import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'

import logo from '../images/logo.svg'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    text-align: center;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['denia', 'restaurante', 'bar']} />
    <Wrap>
      <img src={logo} />
      <p>Plaza Oculista Buigues 6, Denia, 03700 Alicante</p>
      <p>Tel. 96 599 67 88</p>
    </Wrap>
  </Layout>
)

export default IndexPage
